import React, { Component } from 'react';
import './css/App.css';
import './css/bootstrap3.3.6.min.css';
import './css/style.css';
import './css/loader.css';
import './css/font-awesome.min.css';
import './css/cookieBar.min.css';
import './css/effects/portfolio.css';
import './css/effects/normalize.css';
import './css/effects/component.css';
import './css/responsive.css';
import scriptLoader from 'react-async-script-loader'

import  Header  from './components/header.js';
import  Main  from './components/main.js';
import  Footer  from './components/footer.js';
import TagManager from 'react-gtm-module'

const tagManagerArgs = {
    gtmId: 'GTM-5NNSNP8',
    dataLayer: {
 'event': 'updatevirtualpath'
 }
}

var loadScript = function (src) {
   var tag = document.createElement('script');
   tag.async = true;
   tag.src = src;
   document.getElementsByTagName('body')[0].appendChild(tag);
}
TagManager.initialize(tagManagerArgs)
class NoMatchExample extends Component {	
render() {
  return (
	  <div id="wrapper">
		<Header/>
		<Main/>
		<Footer/>
	  </div>

 

		
  )
  }
}


export default scriptLoader(  
    '/js/jquery-1.11.2.min.js',
    '/js/bootstrap.min.js',
    '/js/nav.js',
    '/js/jquery.cookieBar.min.js',
    '/js/effects/masonry.pkgd.min.js',
    '/js/effects/imagesloaded.js',
    '/js/effects/classie.js',
    '/js/effects/AnimOnScroll.js',
    '/js/effects/modernizr.custom.js',
    '/js/effects/anime.min.js',
    '/js/html5shiv.js'
)(NoMatchExample)