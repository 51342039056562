import React from 'react'
import { Routes, Route } from 'react-router-dom'
import Portfolio from './../pages/portfolio.js';
import Home from './../pages/home.js';
import About from './../pages/about.js';
import Contact from './../pages/contact.js';
import Blog from './../pages/blog.js';
import Error404 from './../pages/404.js';
import Thanx from './../pages/thanx.js';
import MentionsLegales from './../pages/legales.js';

const Main = () => (
      <Routes onUpdate={() => window.scrollTo(0, 0)}>
        <Route path="*" element={ <Error404/> } />
        <Route path="/"  element={ <Home/> }/>
        <Route path="/freelance-web-nantes-st-nazaire-portfolio"  element={ <Portfolio/> }/>
        <Route path="/contact-freelance-web-nantes-st-nazaire"  element={ <Contact/> }/>
        <Route path="/freelance-web-nantes-st-nazaire-about"  element={ <About/> }/>
        <Route path="/creer-site-internet-nantes-st-nazaire-blog"  element={ <Blog/> }/>
        <Route path="/mentions-legales"  element={ <MentionsLegales/> }/>
        <Route path="/thank-you"  element={ <Thanx/> } />
      </Routes>

)

export default Main
