import React, { Component } from 'react';
import ReactGoogleMaps from './inc/map.js';
import ContactForm from './inc/contactform.js';
import '../css/contact.css';


import {Helmet} from "react-helmet";
import carteVisit from './img/carte-visite-ggerard.jpg';


class Contact extends Component {
  componentDidMount() {
  window.scrollTo(0,0);
}
  render() {

    return (

        <main role="main-inner-wrapper" className="container">
          <Helmet>
            <title>Contact création site Internet Nantes St Nazaire</title>
    <meta charset="UTF-8" />                <meta name="description" content="Contact Gaël GERARD Freelance Web dans la région de Nantes St Nazaire." />
            <meta property="og:title" content="Contact création site Internet Nantes St Nazaire" />
              <meta property="og:image" content={carteVisit} />

            <meta property="og:locale" content="fr_FR" />
<meta property="og:type" content="page" />
<meta property="og:description" content="Professionnel du web depuis près de 20 ans, j&#039;accompagne les entreprises dans la création et la refonte de leur site Internet. Implanté à Cordemais entre Nantes et Saint Nazaire, j&#039;apporte une réponse personnalisée à vos besoins en communication sur Internet : Sites vitrine, e-commerce, référencement naturel..." />
<meta property="og:url" content="https://www.gaelgerard.com/" />
<meta property="og:site_name" content="Freelance web Nantes St Nazaire" />
<meta property="article:publisher" content="https://www.facebook.com/gaelgerardwebnantes/" />
<meta property="og:image" content={carteVisit} />
<meta property="og:image:secure_url" content={carteVisit} />
<meta name="twitter:card" content="summary" />
<meta name="twitter:description" content="Faîtes réaliser votre site Internet par un professionnel de la région Professionnel du web depuis près de 20 ans, j&#039;accompagne les entreprises dans la création et la refonte de leur site [&hellip;]" />
<meta name="twitter:title" content="Contact - Freelance web Nantes St Nazaire" />
<meta name="twitter:site" content="@gaelgerard" />
<meta name="twitter:image" content={carteVisit} />
<meta name="twitter:creator" content="@gaelgerard" />
          </Helmet>
               <div className="wrapper">
            	<div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
      	<article role="pge-title-content" className="contact-header">

                    	<header>

                        	<h1><span>Salut,</span> J' <i className="fa fa-heart" aria-hidden="true"></i> avoir de vos nouvelles.</h1>

                        </header>
                        <p>Une question sur votre site web ou mobile&nbsp;? Contactez-moi par un des moyens ci-dessous.</p>
                        <p>Je me ferai une joie de vous répondre.</p><br/>
                        <p className="rdvclients">Nouveau ! <a target="_blank" href="https://rdvclients.fr/rendez-vous-direct/gael-gerard-goodgame-communication/?utm_source=site-ggcom&amp;utm_medium=referral&amp;utm_campaign=page-contact">Programmez un RDV Direct</a>
                        </p><br/>
                        <p className="liens"><a href="tel:0285523866"><i className="fa fa-phone" aria-hidden="true"></i> 02 85 52 38 66</a><a href="mailto:info@gaelgerard.com"><i className="fa fa-envelope" aria-hidden="true"></i> info@gaelgerard.com</a></p>
                    </article>

                </div>


                <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">

                        <div className="demo-wrapper contact">
                        	<div id="surabaya"><ReactGoogleMaps/></div>

                        </div>

                </div>

</div>

               <div className="contat-form wrapper col-xs-12 mt-3">
                <h2 className="mb-5 mt-3">Formulaire de contact</h2>
               		  <div id="message"></div>

                              <ContactForm/>

               </div>
               </main>




    );
  }
}
export default Contact
