import React, { Component } from 'react';
import ContactForm from './inc/contactform.js';
import '../css/contact.css';

import {Helmet} from "react-helmet";
import logoFooter from './img/logoGGERARD-carre250.png';


     

class Error404 extends Component {
  
  
  componentDidMount() {
  window.scrollTo(0,0);
}
  render() {
    return (
      
        <main role="main-inner-wrapper" className="container">
          <Helmet>
            <title>Erreur 404 Page introuvable</title>
    <meta charset="UTF-8" />    
            <meta property="og:image" content={logoFooter} />
            <meta name="robots" content="noindex" />
          </Helmet>
            	<div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 grid">
      	<article role="pge-title-content" className="contact-header">

                    	<header>
                              <h1><span>404</span> No match for <code>your_query</code></h1>
                        </header>          
                    </article>

                </div>


                <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 grid">

      	<article role="pge-title-content" className="contact-header">
                        
                              <header><h1>Sorry!</h1></header>
                              <p>La page que vous cherchiez n'existe plus <br/>ou elle a été déplacée !</p> <br/>
                              <p className="lead">Peut-être sur notre blog ?</p>
                              <a class="btn btn-primary btn-lg mt-2" href={"https://dev.gaelgerard.com/?utm_source=site&amp;utm_medium=referral&utm_campaign=page_blog"} target="blank" rel="noopener noreferrer">Rechercher sur le blog dev.gaelgerard.com  <i class="fa fa-external-link" aria-hidden="true"></i></a>
                        
</article>
            

                </div>


               <div className="contat-form wrapper">

                <h2 className="mb-5">Formulaire de contact</h2>
               		  <div id="message"></div>

                              <ContactForm/>

               </div>
               </main>


                

    );
  }
}
export default Error404