import React, { Component } from 'react';
import {Helmet} from "react-helmet";
import carteVisit from './img/carte-visite-ggerard.jpg';
import { Link } from 'react-router-dom';
import {appendScript} from '../utils/appendScript'
import {removeScript} from '../utils/removeScript'



class Posts extends Component {

	componentDidMount() {
		appendScript("/js/effects/portfolio-effects.js");
		window.scrollTo(0,0);
	  }
	  componentDidUpdate() {
		appendScript("/js/effects/portfolio-effects.js");
	  }
	  componentWillUnmount () {
		  removeScript("/js/effects/portfolio-effects.js")
	  }
  render() {
    return (

        <main role="main-inner-wrapper" className="container">
          <Helmet>
            <title>Freelance WordPress Portfolio création site Internet et boutique en ligne</title>
    <meta charset="UTF-8" />                <meta name="description" content="Freelance WordPress création ou refonte de site internet et boutique en ligne sur mesure, accompagnement complet. Découvrez mes différentes réalisations. " />
            <meta property="og:title" content="Portfolio création site Internet Nantes St Nazaire" />
              <meta property="og:image" content={carteVisit} />

            <meta property="og:locale" content="fr_FR" />
<meta property="og:type" content="page" />
<meta property="og:description" content="Découvrez mes différentes réalisations de Freelance Web. Création ou refonte de votre site ou boutique en ligne sur mesure avec un accompagnement complet." />
<meta property="og:url" content="https://www.gaelgerard.com/" />
<meta property="og:site_name" content="Freelance web Nantes St Nazaire" />
<meta property="article:publisher" content="https://www.facebook.com/gaelgerardwebnantes/" />
<meta property="og:image" content={carteVisit} />
<meta property="og:image:secure_url" content={carteVisit} />
<meta name="twitter:card" content="summary" />
<meta name="twitter:description" content="Découvrez mes différentes créations ou refontes de sites internet ou boutiques en ligne sur mesure avec un accompagnement complet." />
<meta name="twitter:title" content="Portfolio - Freelance web Nantes St Nazaire" />
<meta name="twitter:site" content="@gaelgerard" />
<meta name="twitter:image" content={carteVisit} />
<meta name="twitter:creator" content="@gaelgerard" />
          </Helmet>
        	<svg className="hidden">
			<symbol id="icon-arrow" viewBox="0 0 24 24">
				<title>arrow</title>
    <meta charset="UTF-8" />    				<polygon points="6.3,12.8 20.9,12.8 20.9,11.2 6.3,11.2 10.2,7.2 9,6 3.1,12 9,18 10.2,16.8 "/>
			</symbol>
			<symbol id="icon-drop" viewBox="0 0 24 24">
				<title>drop</title>
    <meta charset="UTF-8" />    				<path d="M12,21c-3.6,0-6.6-3-6.6-6.6C5.4,11,10.8,4,11.4,3.2C11.6,3.1,11.8,3,12,3s0.4,0.1,0.6,0.3c0.6,0.8,6.1,7.8,6.1,11.2C18.6,18.1,15.6,21,12,21zM12,4.8c-1.8,2.4-5.2,7.4-5.2,9.6c0,2.9,2.3,5.2,5.2,5.2s5.2-2.3,5.2-5.2C17.2,12.2,13.8,7.3,12,4.8z"/><path d="M12,18.2c-0.4,0-0.7-0.3-0.7-0.7s0.3-0.7,0.7-0.7c1.3,0,2.4-1.1,2.4-2.4c0-0.4,0.3-0.7,0.7-0.7c0.4,0,0.7,0.3,0.7,0.7C15.8,16.5,14.1,18.2,12,18.2z"/>
			</symbol>
			<symbol id="icon-cross" viewBox="0 0 24 24">
				<title>cross</title>
    <meta charset="UTF-8" />    				<path d="M 5.5,2.5 C 5.372,2.5 5.244,2.549 5.146,2.646 L 2.646,5.146 C 2.451,5.341 2.451,5.659 2.646,5.854 L 8.793,12 2.646,18.15 C 2.451,18.34 2.451,18.66 2.646,18.85 L 5.146,21.35 C 5.341,21.55 5.659,21.55 5.854,21.35 L 12,15.21 18.15,21.35 C 18.24,21.45 18.37,21.5 18.5,21.5 18.63,21.5 18.76,21.45 18.85,21.35 L 21.35,18.85 C 21.55,18.66 21.55,18.34 21.35,18.15 L 15.21,12 21.35,5.854 C 21.55,5.658 21.55,5.342 21.35,5.146 L 18.85,2.646 C 18.66,2.451 18.34,2.451 18.15,2.646 L 12,8.793 5.854,2.646 C 5.756,2.549 5.628,2.5 5.5,2.5 Z"/>
			</symbol>
			<symbol id="icon-menu" viewBox="0 0 24 24">
				<title>menu</title>
    <meta charset="UTF-8" />    				<path d="M23.8,6H0.1V3h23.7C23.8,3,23.8,6,23.8,6z M23.8,10.5H0.1v3h23.7C23.8,13.5,23.8,10.5,23.8,10.5z M24,18H10v3h14V18z"/>
			</symbol>
			<symbol id="icon-magnifier" viewBox="0 0 490.8 490.8">
				<title>magnifier</title>
    <meta charset="UTF-8" />    				<path d="M364.8,299.55c46.3-75.8,36.9-176.3-28.6-241.9c-76.8-76.8-201.8-76.8-278.6,0s-76.8,201.8,0,278.5c65.5,65.5,166,74.9,241.9,28.6L412,477.25c18,18,47.3,18,65.3,0s18-47.3,0-65.3L364.8,299.55z M295.5,295.55c-54.4,54.4-142.8,54.4-197.1,0c-54.4-54.4-54.4-142.8,0-197.1c54.4-54.4,142.8-54.4,197.1,0C349.8,152.75,349.8,241.15,295.5,295.55z M220,171.95h59.4v45.3H220v59.4h-45.3v-59.4h-59.3v-45.3h59.4v-59.4h45.3v59.4H220z"/>
			</symbol>
            </svg>

            <div className="row">
            <div className="container">
                	<article role="pge-title-content" className="blog-header">

                    	<header>

                        	<h1><span>Portfolio <br/>Freelance WordPress</span> Happy few parmi mes réalisations</h1>
<p>Freelance WordPress dans la région de Nantes, confiez moi la création de votre site internet ou boutique en ligne (sous WordPress ou non).</p>
<p className="mt-3">J'accompagne les entreprises et les agences dans la création et la refonte de site ou boutique en ligne sur mesure avec un accompagnement complet.</p>
<p className="mt-3">Je crée des sites internet entièrement administrables pour les novices en informatique et les spécialistes du Webmarketing.</p>
<p className="mt-3">Découvrez ci-dessous quelques-unes de mes dernières réalisations et cliquez sur les images pour lire plus de détails.</p>
                        </header>

                    </article>
                    </div>
            	<div className="grid portfolio">
                    <ul className="grid-lod effect-2" id="grid">
						<li className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                	<article role="pge-title-content" className="blog-header">

                        <p>Demandez moi plus de précisions sur mes autres réalisations en m'envoyant un petit message.</p>
                        <Link to="/contact-freelance-web-nantes-st-nazaire" className="btn btn-primary btn-lg mt-2">Demandez plus d'informations</Link>
</article>

       </li>

						<li className="grid__item col-xs-12 col-sm-6 col-md-6 col-lg-6" id="post-dilocaldansmonassiette">

						<div className="product">
							<div className="product__bg"></div>
                            <section className="blog-content">



                                <figure>

                                    <div className="post-date">

			 <span>
	Woocommerce</span>

                                    </div>
		<img src="https://i0.wp.com/dev.gaelgerard.com/wp-content/uploads/2020/01/wordpress-woocommerce-responsive-design-pontchateau-2020-devices.jpg?resize=1200%2C936&ssl=1" className="product__img" alt="" width="600"/>


                                </figure>



                                <article>

							<h2 className="product__title">Boutique en ligne WordPress Woocommerce</h2>
							<p className="product__subtitle">DU LOCAL DANS MON ASSIETTE</p>
							<div className="product__description"><p>Boutique en ligne de produits de la ferme, Épicerie, primeurs et de cosmétiques naturels et locaux dans la région de Pontchâteau, Savenay et St Nazaire.</p><p>Alexandre Bard et sa compagne gèrent eux même presque 500 références de produits dans l'interface très intuitive de WordPress Woocommerce jusqu'à la distribution en point de collecte et même à domicile.</p><p>WordPress offre de nombreuses fonctionnalités par défaut pour les boutiques en lignes pour un budget plus limité en développement.</p><p>Le design est adapté de la charte graphique de <a rel="noreferrer noopener" href="https://www.lovinsky.fr/" target="_blank">Lovinsky</a>, adaptatée avec mon thème WordPress Bootstrap maison</p>
</div>
							<div className="product__price"></div>
							<div className="product__cart"><a href="https://www.dulocaldansmonassiette.fr/?utm_source=site-ggcom&utm_medium=referral&utm_campaign=portfolio&utm_term=button" target="_blank" rel="noopener noreferrer">Voir le site</a></div>

                                </article>

                            </section>

						</div>
                        </li>
						<li className="grid__item col-xs-12 col-sm-6 col-md-6 col-lg-6" id="post-movibook">

						<div className="product">
							<div className="product__bg"></div>
                            <section className="blog-content">



                                <figure>

                                    <div className="post-date">

			 <span>
	Wordpress</span>

                                    </div>
					                                    <img src="/img/bootstrap-theme-wordpress-movibook.jpg" className="product__img" alt="bootstrap-theme-wordpress-movibook"/>


                                </figure>



                                <article>

							<h2 className="product__title">Migration WordPress thème Bootstrap</h2>
							<p className="product__subtitle">Movi-Book</p>
							<div className="product__description"><p>Refonte WordPress d'un site One Page HTML5/CSS3 réalisé avec un logiciel pour créer un site internet gratuit sur un site multi-page administrable WordPress.</p>
                            <p>Le site Movi-Book dispose de nombreuses fonctionnalités pour l'optimisation du référencement et de l'expérience utilisateurs UX/UI.</p>
                            <p>Différents types de formulaires ont été mis en place pour faciliter la prise de contact et notamment sur les fiches produits du catalogue vitrine (sans paiement) qui permettent de demander un devis pré rempli sur le modèle sélectionné.</p>
                            <p>Côté Back-Office, une liaison a été mise en place entre les demandes de contacts WordPress et la base de données Sellsy du client afin d'ajouter les Leads au CRM en 1 clic.</p>

</div>
							<div className="product__price"></div>
							<div className="product__cart"><a href="https://www.movi-book.com/" target="_blank" rel="noopener noreferrer">Voir le site</a></div>

                                </article>

                            </section>

						</div>
                        </li>


						<li className="grid__item col-xs-12 col-sm-6 col-md-6 col-lg-6" id="post-mainsdelaine">

						<div className="product">
							<div className="product__bg"></div>
                            <section className="blog-content">



                                <figure>

                                    <div className="post-date">

			 <span>
	Wordpress</span>

                                    </div>
					                                    <img src="/img/boutique-en-ligne-wordpress-woocommerce-600x326.jpg" className="product__img" alt="boutique-en-ligne-wordpress-woocommerce-600x326"/>


                                </figure>



                                <article>

							<h2 className="product__title">Boutique WordPress Woocommerce</h2>
							<p className="product__subtitle">Mainsdelaine</p>
							<div className="product__description"><p>Création d&rsquo;une boutique en ligne à partir du CMS WordPress avec le plugin Woocommerce et l&rsquo;adaptation du thème Storefront.</p>
                            <p>Grâce au fonctionnement très intuitif de la solution Woocommerce pour WordPress, Isabelle Mainsdelaine gère elle même l'ensemble de sa boutique, pour créer de nouvelles fiches produits, ajouter des photos...</p>
</div>
							<div className="product__price"></div>
							<div className="product__cart"><a href="https://mainsdelaine.fr/" target="_blank" rel="noopener noreferrer">Voir le site</a></div>

                                </article>

                            </section>

						</div>
                        </li>



						<li className="grid__item col-xs-12 col-sm-6 col-md-6 col-lg-6" id="post-newgames">

						<div className="product">
							<div className="product__bg"></div>
                            <section className="blog-content">



                                <figure>

                                    <div className="post-date">

			 <span>
	Prestashop</span>

                                    </div>
				                                    <img src="/img/migration-prestashop-1.jpg" className="product__img" alt="migration-prestashop-1"/>


                                </figure>



                                <article>

							<h2 className="product__title">Migration Prestashop 1.4 vers 1.6</h2>
							<p className="product__subtitle">NG Sistem</p>
							<div className="product__description">
                  <p>Migration d&rsquo;une boutique Prestashop 1.4 vers la dernière version 1.6 pour profiter des dernières fonctionnalités du CMS moteur de boutique.</p>
<p>Intégration de la charte graphique existante sur le nouveau système de thème pour Prestashop à partir du thème par défaut de Prestashop 1.6.</p>
<p>Le nouveau site est de plus adapté aux mobiles et tablette grâce au design Responsive aujourd'hui préconisé par Google.</p>
</div>
							<div className="product__price"></div>
							<div className="product__cart"><a href="http://www.newgames.fr" target="_blank" rel="noopener noreferrer">Voir le site</a></div>

                                </article>

                            </section>

						</div>
                        </li>



						<li className="grid__item col-xs-12 col-sm-6 col-md-6 col-lg-6" id="post-reseau-pointe">

						<div className="product">
							<div className="product__bg"></div>
                            <section className="blog-content">



                                <figure>

                                    <div className="post-date">

			 <span>
	Wordpress</span>

                                    </div>
			                                    <img src="/img/espace-adherent-wordpress-600x499.jpg" className="product__img" alt="espace-adherent-wordpress-600x499"/>


                                </figure>



                                <article>

							<h2 className="product__title">Extranet adhérents WordPress</h2>
							<p className="product__subtitle">Réseau Point E</p>
							<div className="product__description"><p>Blog d&rsquo;actualités sur une base de CMS WordPress avec une partie publique et privée.</p>
<p>Un annuaire avec une carte Googlemap administrable permet aux internautes de trouver l&rsquo;adhérent le plus proche avec un accès à sa fiche d&rsquo;information.</p>
<p>Chaque Adhérent au réseau d'affiliés peut modifier sa fiche pour l\'enrichir et la référencer dans les annuaires et moteurs de recherche pour être plus visible.</p>
</div>
							<div className="product__price"></div>
							<div className="product__cart"><a href="http://reseau.point-e.fr" target="_blank" rel="noopener noreferrer">Voir le site</a></div>

                                </article>

                            </section>

						</div>
                        </li>




						<li className="grid__item col-xs-12 col-sm-6 col-md-6 col-lg-6" id="post-cmfgroup">

						<div className="product">
							<div className="product__bg"></div>
                            <section className="blog-content">



                                <figure>

                                    <div className="post-date">

			 <span>
	Wordpress</span>

                                    </div>
	                                    <img src="/img/Screenshot_1-600x349.png" className="product__img" alt=""/>


                                </figure>



                                <article>

							<h2 className="product__title">Site multilingue administrable WordPress</h2>
							<p className="product__subtitle">Groupe CMF</p>
							<div className="product__description"><p>Développement WordPress et intégration responsive design multi lingue.</p>
<p>Site entièrement administrable via le backoffice WordPress.</p>
<p>Animations responsive jQuery.</p>
</div>
							<div className="product__price"></div>
							<div className="product__cart"><a href="http://www.cmf-groupe.com/" target="_blank" rel="noopener noreferrer">Voir le site</a></div>

                                </article>

                            </section>

						</div>
                        </li>



						<li className="grid__item col-xs-12 col-sm-6 col-md-6 col-lg-6" id="post-planeteparquets">

						<div className="product">
							<div className="product__bg"></div>
                            <section className="blog-content">



                                <figure>

                                    <div className="post-date">

			 <span>
	Wordpress</span>

                                    </div>
                                    <img src="/img/planete-parquets-600x294.png" className="product__img" alt=""/>


                                </figure>



                                <article>

							<h2 className="product__title">Site vitrine administrable WordPress</h2>
							<p className="product__subtitle">Planète Parquets</p>
							<div className="product__description"><p>Développement WordPress et intégration responsive design du site Planète Parquets.</p>
<p>Personnalisation de l&rsquo;UI pour un prise en main simplifiée du backoffice.</p>
<p>Site entièrement administrable enrichi de quelques animations jQuery.</p>
</div>
							<div className="product__price"></div>
							<div className="product__cart"><a href="http://www.planeteparquets.fr/" target="_blank" rel="noopener noreferrer">Voir le site</a></div>

                                </article>

                            </section>

						</div>
                        </li>








						<li className="grid__item col-xs-12 col-sm-6 col-md-6 col-lg-6" id="post-demoweb">

						<div className="product">
							<div className="product__bg"></div>
                            <section className="blog-content">



                                <figure>

                                    <div className="post-date">

			 <span>
	From Scratch</span>

                                    </div>

	<img src="/img/Capture-demoweb-600x406.png" className="product__img" alt=""/>

                                </figure>



                                <article>

							<h2 className="product__title">Présentation interactive web et mobile</h2>
							<p className="product__subtitle">Perso</p>
							<div className="product__description"><p>Quoi de mieux pour présenter son activité qu&rsquo;une page web spécifique. Cette démo réalisée en html5 et css3 présente de manière interactive quelques unes des possibilités offertes à nos clients en développement web.</p></div>
							<div className="product__price"></div>
							<div className="product__cart"><a href="https://demo.gaelgerard.com" target="_blank" rel="noopener noreferrer">Voir le site</a></div>

                                </article>

                            </section>

						</div>
                        </li>

        </ul>
      </div>
      </div>
      </main>
    );
  }
}
export default Posts
