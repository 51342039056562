import React, { Component } from 'react';
import { Link } from 'react-router-dom';


import {Helmet} from "react-helmet";
import carteVisit from './img/carte-visite-ggerard.jpg';


class Blog extends Component {
  componentDidMount() {
  window.scrollTo(0,0);
}
  render() {
    
    return (
     
        <main role="main-inner-wrapper" className="container">
          <Helmet>
            <title>Présentation Blog créer un site internet création site Internet Nantes St Nazaire</title>
    <meta charset="UTF-8" />                <meta name="description" content="Blog Gaël GERARD Freelance Web dans la région de Nantes St Nazaire." />
            <meta property="og:title" content="Créer un site internet, le Blog de Gaël GERARD qui s'intéresse à la création site Internet Nantes St Nazaire" />
              <meta property="og:image" content={carteVisit} />
            
            <meta property="og:locale" content="fr_FR" />
<meta property="og:type" content="page" />
<meta property="og:description" content="Professionnel du web depuis près de 20 ans, j&#039;accompagne les entreprises dans la création et la refonte de leur site Internet. Implanté à Cordemais entre Nantes et Saint Nazaire, j&#039;apporte une réponse personnalisée à vos besoins en communication sur Internet : Sites vitrine, e-commerce, référencement naturel..." />
<meta property="og:url" content="https://www.gaelgerard.com/" />
<meta property="og:site_name" content="Freelance web Nantes St Nazaire" />
<meta property="article:publisher" content="https://www.facebook.com/gaelgerardwebnantes/" />
<meta property="og:image" content={carteVisit} />
<meta property="og:image:secure_url" content={carteVisit} />
<meta name="twitter:card" content="summary" />
<meta name="twitter:description" content="Faîtes réaliser votre site Internet par un professionnel de la région Professionnel du web depuis près de 20 ans, j&#039;accompagne les entreprises dans la création et la refonte de leur site [&hellip;]" />
<meta name="twitter:title" content="Présentation Blog créer un site internet - Freelance web Nantes St Nazaire" />
<meta name="twitter:site" content="@gaelgerard" />
<meta name="twitter:image" content={carteVisit} />
<meta name="twitter:creator" content="@gaelgerard" />
          </Helmet>
          <div className="row">
            	<div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 grid">
      	<article role="pge-title-content" className="text-justify">

                    	<header className="text-center">

                        	<h1><span>Le Blog</span>Créer un site internet,</h1>

                        </header>
                        <p>Sans entrer dans les détails techniques, le site sur lequel vous naviguez actuellement est développé avec la technologie <a href="https://search.lilo.org/searchweb.php?q=reactjs%20fran%C3%A7ais&page=1" title="Recherche avec Lilo dans une nouvelle fenêtre" target="blank" rel="noopener noreferrer">ReactJS  <i className="fa fa-external-link" aria-hidden="true"></i></a>.</p>
                        <p>Cette techno qui a pour avantage une très grande fluidité entre les pages du site, présente l'inconvénient de devoir charger intégralement le site web au début de la visite.</p>
                        <p>De fait, l'ajout d'un blog sur ce site aurait alourdi le chargement du site et donc limité le nombre de billets.</p>
                        <p>C'est pourquoi nous avons décidé de vous donner l'accès à notre blog de développement :</p>
                        <p><a className="btn btn-primary btn-lg mt-2" href="https://dev.gaelgerard.com/?utm_source=site-ggreact&utm_medium=referral&utm_campaign=page-blog&utm_term=button" target="blank" rel="noopener noreferrer">Créer un site internet  <i className="fa fa-external-link" aria-hidden="true"></i></a></p>
                        <p>Vous n'avez pas <strong>le temps de créer un site internet ?</strong> Contactez-nous par l'un des moyens ci-dessous, nous nous ferons une joie de vous répondre.</p><br/>
                        <p className="liens"><a href="tel:0285523866"><i className="fa fa-phone" aria-hidden="true"></i> 02 85 52 38 66</a><a href="mailto:info@gaelgerard.com"><i className="fa fa-envelope" aria-hidden="true"></i> info@gaelgerard.com</a></p>
                        <p className="liens btn mt-2"><Link to="/contact-freelance-web-nantes-st-nazaire" id="presblogContact"><i className="fa fa-edit" aria-hidden="true"></i> Formulaire de contact</Link></p>

                    </article>

                </div>


                <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">

                        <div className="demo-wrapper">

                        	<div id="surabaya" className="captureBlog"><a href="https://dev.gaelgerard.com/?utm_source=site-ggreact&utm_medium=referral&utm_campaign=page-blog&utm_term=image" target="blank" rel="noopener noreferrer"><img src="/img/bootstrap-theme-wordpress-blog-devggcom.jpg" className="product__img" alt="bootstrap theme wordpress blog dev.gaelegerard.com"/></a></div>

                        </div>

                </div>


             </div>   


               </main>


                

    );
  }
}
export default Blog