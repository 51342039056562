import React, { Component } from 'react';
import carteVisit from './img/carte-visite-ggerard.jpg';
import {Helmet} from "react-helmet";
import { Link } from 'react-router-dom';
import {appendScript} from '../utils/appendScript'
import {removeScript} from '../utils/removeScript'
class Home extends Component {
    
  componentDidMount() {
    appendScript("/js/effects/portfolio-effects.js");
    window.scrollTo(0,0);
  }
  componentDidUpdate() {
    appendScript("/js/effects/portfolio-effects.js");
  }
  componentWillUnmount () {
      removeScript("/js/effects/portfolio-effects.js")
  }
  render() {
    return (
     
         <main role="main-home-wrapper" className="container">
              <Helmet>
                <title>Freelance web Nantes Saint Nazaire</title>
    <meta charset="UTF-8" />                    <meta name="description" content="Freelance Web dans la région de Nantes St Nazaire, depuis près de 20 ans, j'accompagne les entreprises dans la création et la refonte de leur site Internet." />
                <meta property="og:title" content="Création site Internet Nantes St Nazaire, faîtes réaliser votre site Internet par un professionnel de la région" />
                <meta property="og:image" content={carteVisit} />
                
                <meta property="og:locale" content="fr_FR" />
    <meta property="og:type" content="page" />
    <meta property="og:description" content="Professionnel du web depuis près de 20 ans, j&#039;accompagne les entreprises dans la création et la refonte de leur site Internet. Implanté à Cordemais entre Nantes et Saint Nazaire, j&#039;apporte une réponse personnalisée à vos besoins en communication sur Internet : Sites vitrine, e-commerce, référencement naturel..." />
    <meta property="og:url" content="https://www.gaelgerard.com/" />
    <meta property="og:site_name" content="Freelance web Nantes St Nazaire" />
    <meta property="article:publisher" content="https://www.facebook.com/gaelgerardwebnantes/" />
    <meta property="og:image" content={carteVisit} />
    <meta property="og:image:secure_url" content={carteVisit} />
    <meta name="twitter:card" content="summary" />
    <meta name="twitter:description" content="Faîtes réaliser votre site Internet par un professionnel de la région Professionnel du web depuis près de 20 ans, j&#039;accompagne les entreprises dans la création et la refonte de leur site [&hellip;]" />
    <meta name="twitter:title" content="Freelance web Nantes St Nazaire" />
    <meta name="twitter:site" content="@gaelgerard" />
    <meta name="twitter:image" content={carteVisit} />
    <meta name="twitter:creator" content="@gaelgerard" />
    
                <link rel="canonical" href="https://www.gaelgerard.com" />
                
                
              </Helmet>
            <div className="row">

            

            	<section className="col-xs-12 col-sm-6 col-md-6 col-lg-6 ">

                	<article role="pge-title-content">

                    	<header>

                        	<h1><span>Gaël GÉRARD</span> Développeur Web & Chef de Projet Digital</h1>
                            <h2>Bienvenue sur mon site !</h2>
                        </header>


<p>
Je suis Gaël GÉRARD, développeur web et chef de projet digital avec plus de 15 ans d'expérience dans la création et la gestion de projets numériques.</p>
<p className='mt-3'> Passionné par les technologies web, je travaille en freelance depuis 2013, 
offrant des solutions personnalisées à mes clients, de la conception de sites web aux stratégies de marketing digital.</p>
<Link to="/contact-freelance-web-nantes-st-nazaire" className="btn btn-primary btn-lg mt-2">Demandez plus d'informations</Link>
                    </article>

                </section>

                

                <section className="col-xs-12 col-sm-6 col-md-6 col-lg-6 grid first">

                

                        	<figure className="effect-oscar">

                            <img src="img/freelance-wordpress-nantes.jpg" alt="" className="img-responsive"/>

                            <figcaption>

                            	

                                    <h2>Une idée pour <span>créer un site Internet ? </span></h2>

                                    <p>Le CMS Wordpress dispose par défaut de toutes les options pour gagner du temps et donc de l'argent dans la réalisation de votre projet web</p>
<p><br/>Cliquez pour le tester !</p>
                                    <Link to="/creer-site-internet-nantes-st-nazaire-blog" id="homeGgcom">View more</Link>

                                

                            </figcaption>

                        </figure>
                </section>

                


                <div className="clearfix"></div>

                

                <section className="col-xs-12 col-sm-6 col-md-6 col-lg-6 grid">

                	<ul className="grid-lod effect-2" id="grid">

                    	<li>

	<figure className="effect-oscar">

                    	<img src="img/Web-developer-career.jpg" alt="" className="img-responsive"/>

                        <figcaption>

                        	<h2>Pour les agences<span> de communication et publicité</span></h2>

							<p>J'interviens régulièrement en renfort de grandes agences pour la gestion des projets web de leurs clients, la réalisation de mailings, bandeaux publicitaires, sites web mais aussi dans la formation des utilisateurs finaux.</p>

<p><br/>Cliquez pour voir mes réalisations !</p>

							<Link to="/freelance-web-nantes-st-nazaire-portfolio" id="homePortfolio">View more</Link>

                        </figcaption>

                    </figure>
                        </li>

                        </ul>
                        </section>
                        <section className="col-xs-12 col-sm-6 col-md-6 col-lg-6 ">

                <article role="pge-title-content">

                <h2 className='mt-3'>Mes compétences principales</h2>
                        <p className='mt-3'>
                            Je propose des services sur mesure pour répondre à vos besoins en matière de développement web, de gestion de projet et d'optimisation de la performance.
                        </p>
                        <ul className='mt-3 ml-3'>
                            <li><p><strong>Développement Full Stack</strong> : PHP, SQL, HTML, CSS, JavaScript, jQuery, React, Node.js</p></li>
                            <li><p><strong>CMS</strong> : Spécialiste WordPress, Prestashop, Drupal, Joomla</p></li>
                            <li><p><strong>Outils et Frameworks</strong> : Git, WP-CLI, SASS, Bootstrap, WebRTC</p></li>
                            <li><p><strong>Web Performance & SEO</strong> : Optimisation des performances (Core Web Vitals), SEO, SEA</p></li>
                            <li><p><strong>Stratégies Web</strong> : Création de stratégies digitales, community management, newsletters, SEM</p></li>
                        </ul>
                  </article>

                </section>
                <div className="clearfix"></div>
                <section className="col-12 grid">
                <article role="pge-title-content">
                <h2>Services à la carte ou au forfait</h2>
        <h4 className='mt-3'>
          <strong>
            Je propose une large gamme de services, allant du développement de sites web complets à l’accompagnement dans la gestion et l’optimisation de projets digitaux.
          </strong>
        </h4>
        <p className='mt-3'><strong>Développement de sites internet sur mesure</strong> : Créez des sites performants et adaptés à vos besoins avec les dernières technologies.</p>
        <p className='mt-3'><strong>Gestion de projets web</strong> : De la conception à la mise en production, je vous accompagne dans toutes les phases de votre projet digital.</p>
        <p className='mt-3'p><strong>Optimisation SEO et marketing digital</strong> : Améliorez la visibilité et le référencement de votre site internet avec des stratégies personnalisées.</p>
              </article>
        </section>
                <div className="clearfix"></div>
                <section className="col-xs-12 col-sm-6 col-md-6 col-lg-6 mt-3">
                <article role="pge-title-content">
                <h2 className='mt-3'>Projets récents</h2>
        <h3 className='mt-3'>Plateforme événementielle pour Office Depot</h3>
        <p className='mt-3'>
            Développement d'une plateforme d'inscription aux événements RH avec intégration de PHP et SQL. Gestion des performances et maintenance continue depuis 2014.
        </p>

        <h3 className='mt-3'>CMS multi-sites pour Lyreco</h3>
        <p className='mt-3'>
            Développement et gestion d’un CMS sur mesure pour 60 sites institutionnels dans 30 filiales internationales, avec refonte graphique et optimisation des performances en PHP.
        </p>

        <h3 className='mt-3'>Solutions e-commerce WordPress pour Movi-Book</h3>
        <p className='mt-3'>
            Développement d’un système multi-site WordPress pour la gestion de brochures vidéo, avec centralisation des mises à jour produits pour les revendeurs.
        </p>
        </article>
                </section>
                <section className="col-xs-12 col-sm-6 col-md-6 col-lg-6 grid mt-3">

                	<ul className="grid-lod effect-2" id="grid">

                        <li>

                        	<figure className="effect-oscar">

                            <img src="/img/gestion-projet-web-digital.jpg" alt="" className="img-responsive"/>

                            <figcaption>

                                <h2>Un projet web <span>ambitieux <br/>et Innovant ?</span></h2>

                                <p>Faites appel à un chef de projet web digital pour mettre en place votre application ou site web. Mon expérience dans la conduite de projets web et multimédia m'a confronté à de nombreuses situations. Saurez-vous encore me surprendre avec votre demande ?</p>

                                <p><br/>Cliquez pour voir ma Timeline !</p>

							<Link to="/freelance-web-nantes-st-nazaire-about" id="homeAbout">View more</Link>
                            </figcaption>

                        </figure>

                        </li>
                    </ul>

                </section>
                <div className="clearfix"></div>

                <section className="col-12 grid">
                <article role="pge-title-content">
        <h2 className='mt-3'>Pourquoi travailler avec moi ?</h2>
            <p className='mt-3'><strong>Expérience confirmée</strong> : Plus de 15 ans d’expérience en développement web et gestion de projets digitaux.</p>
            <p className='mt-3'><strong>Solutions sur mesure</strong> : Chaque projet est unique, et je mets un point d’honneur à proposer des solutions adaptées à vos besoins spécifiques.</p>
            <p className='mt-3'><strong>Suivi à long terme</strong> : J’accompagne mes clients sur la durée, avec un suivi régulier et des services de maintenance adaptés.</p>
            <p>

<Link to="/contact-freelance-web-nantes-st-nazaire" className="btn btn-primary btn-lg mt-2">Demandez plus d'informations</Link>
            </p>
            </article>
</section>

                


            </div>

        </main>
    );
  }
}
export default Home