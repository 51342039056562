import React, { Component } from 'react';
import logo from './../pages/img/logo-rond.svg';
import { NavLink } from 'react-router-dom'

//console.log(logo); // /logo.84287d09.png
class Header extends Component {
  render() {
const activeClass = (route) => { return window.location.pathname === route ? "nav-active" : route }
  // Import result is the URL of your image
  return (
    
        	<header role="header" className="nav-down">
            	<div className="container">
          <div className="site-branding">
					<NavLink to="/" className="custom-logo-link" rel="home" itemProp="url"><img className="App-logo" src={logo} title="Gael" alt="gerard" width="90"/> 
          <span>g</span></NavLink>			
          </div>

                    <nav role="header-nav" className="navy">
                        <ul>

                          <li><NavLink to="/" title="Accueil" id="menuAccueil">Home</NavLink></li>

                            <li><NavLink to="/freelance-web-nantes-st-nazaire-about" title="About" id="menuAbout">À propos</NavLink></li>

                            <li><NavLink to="/freelance-web-nantes-st-nazaire-portfolio" title="Portfolio" id="menuPortfolio">Portfolio</NavLink></li>
                            
                            <li><NavLink to="/creer-site-internet-nantes-st-nazaire-blog" title="Blog" id="menuPortblog">Blog</NavLink></li>

                            <li><NavLink to="/contact-freelance-web-nantes-st-nazaire" title="Contact" id="menuContact">Contact</NavLink></li>

                        </ul>

                    </nav>


                </div>

            </header>
  )
}
}

export default Header;