import React from 'react';
import request from 'superagent'

class StackOverflowProfile extends React.Component {
	constructor(props) {
	  	super(props);
	    var self = this;

		this.state = {
	    	userData: {}
	    };

		request
			.get(`https://api.stackexchange.com/2.2/users/${this.props.userId}?site=stackoverflow`)
			.then(function(response){
				self.setState({ userData: response.body.items[0] });
			});
  }

	render() {
    if (this.state.userData.user_id !== undefined) {
      return <div className='stackoverflow-profile'>
    <h3 className="sr-only">Stack Overflow</h3>
    <a href="https://stackoverflow.com/users/3623080/gael" target="_blank" rel="noreferrer noopener"><img src="//sstatic.net/stackexchange/img/logos/so/so-icon.png" alt="SO Icon" width="60" height="60"/></a>

        <div className='bottom'>
                    <div className='profile-stats-repo'><strong>{this.state.userData.reputation}</strong> RÉPUTATION</div>

          <div className='profile-stats-badge-bronze'>{this.state.userData.badge_counts.bronze}<span className="sr-only">Bronze</span></div>
          <div className='profile-stats-badge-silver'>{this.state.userData.badge_counts.silver}<span className="sr-only">Argent</span></div>
          <div className='profile-stats-badge-gold'>{this.state.userData.badge_counts.gold}<span className="sr-only">Or</span></div>
        </div>
      </div>;
    } else {
    	return <div className='stackoverflow-profile-loading'><span>Loading...</span></div>;
    }
  }
}

export default StackOverflowProfile;