import React, { Component } from 'react';


import {Helmet} from "react-helmet";
import logoFooter from './img/logoGGERARD-carre250.png';


class Legals extends Component {

  componentDidMount() {
  window.scrollTo(0,0);
}
  render() {

    return (

        <main role="main-inner-wrapper" className="container">
          <Helmet>
            <title>Mentions légales création site Internet Nantes St Nazaire</title>
    <meta charset="UTF-8" />                <meta name="description" content="Mentions légales Gaël GERARD Freelance Web dans la région de Nantes St Nazaire." />
            <meta property="og:title" content="Mentions légales création site Internet Nantes St Nazaire" />
            <meta property="og:image" content={logoFooter} />
          </Helmet>

            	<div className="blog-details">

                	<article className="post-details" id="post-details">

                        <header role="blog-header" className="blog-header text-center">
					<h1>
						<span>Mentions</span> légales		</h1>
				</header>

				<div className="enter-content">
					<h2>1. Présentation du site</h2>
			<p>En vertu de l’article 6 de la loi n° 2004-575 du 21 juin 2004 pour la confiance dans l’économie numérique, il est précisé aux utilisateurs du site du <strong>Freelance web Nantes St Nazaire &#8211; Gaël GERARD</strong> l’identité des différents intervenants dans le cadre de sa réalisation et de son suivi :</p>
			<h3>Propriétaire</h3>
			<p><strong>Freelance web Nantes St Nazaire &#8211; Gaël GERARD</strong><br/>
            3 La Haute Moisonnais<br/>
            44360 Cordemais<br/>
            Tél. : 02 85 52 38 66</p>
            			<h3>Hébergement</h3>
			<p><strong>Vercel</strong><br/>
Vercel Inc.<br/>
340 S Lemon Ave #4133<br/>
Walnut, CA 91789<br/>
privacy@vercel.com</p>
			<h2>2. Conditions générales d&rsquo;utilisation du site et des services proposés</h2>
			<p>L&rsquo;utilisation du site du <strong>Freelance web Nantes St Nazaire &#8211; Gaël GERARD</strong> implique l&rsquo;acceptation pleine et entière des conditions générales d&rsquo;utilisation ci-après décrites. Ces conditions d&rsquo;utilisation sont susceptibles d&rsquo;être modifiées ou complétées à tout moment, les utilisateurs du site du <strong>Freelance web Nantes St Nazaire &#8211; Gaël GERARD</strong> sont donc invités à les consulter de manière régulière.</p>
			<p>Ce site est normalement accessible à tout moment aux utilisateurs. Une interruption pour raison de maintenance technique peut être toutefois décidée par le <strong>Freelance web Nantes St Nazaire &#8211; Gaël GERARD</strong>, qui s&rsquo;efforcera alors de communiquer préalablement aux utilisateurs les dates et heures de l&rsquo;intervention.</p>
			<p>Le site du <strong>Freelance web Nantes St Nazaire &#8211; Gaël GERARD</strong> est mis à jour régulièrement par le <strong>Freelance web Nantes St Nazaire &#8211; Gaël GERARD</strong>. De la même façon, les mentions légales peuvent être modifiées à tout moment : elles s&rsquo;imposent néanmoins à l&rsquo;utilisateur qui est invité à s&rsquo;y référer le plus souvent possible afin d&rsquo;en prendre connaissance.</p>
			<h2>3. Description des services fournis</h2>
			<p>Le site du <strong>Freelance web Nantes St Nazaire &#8211; Gaël GERARD</strong> a pour objet de fournir une information concernant l&rsquo;ensemble des activités de la société.</p>
			<p>le <strong>Freelance web Nantes St Nazaire &#8211; Gaël GERARD</strong> s&rsquo;efforce de fournir sur le site du <strong>Freelance web Nantes St Nazaire &#8211; Gaël GERARD</strong> des informations aussi précises que possible. Toutefois, elle ne pourra être tenue responsable des omissions, des inexactitudes et des carences dans la mise à jour, qu&rsquo;elles soient de son fait ou du fait des tiers partenaires qui lui fournissent ces informations.</p>
			<p>Tous les informations indiquées sur le site du <strong>Freelance web Nantes St Nazaire &#8211; Gaël GERARD</strong> sont données à titre indicatif, et sont susceptibles d&rsquo;évoluer. Par ailleurs, les renseignements figurant sur le site du <strong>Freelance web Nantes St Nazaire &#8211; Gaël GERARD</strong> ne sont pas exhaustifs. Ils sont donnés sous réserve de modifications ayant été apportées depuis leur mise en ligne.</p>
			<h2>4. Limitations contractuelles sur les données techniques</h2>
			<p>Le site utilise la technologie JavaScript.</p>
			<p>Le site Internet ne pourra être tenu responsable de dommages matériels liés à l&rsquo;utilisation du site. De plus, l&rsquo;utilisateur du site s&rsquo;engage à accéder au site en utilisant un matériel récent, ne contenant pas de virus et avec un navigateur de dernière génération mis à jour.</p>
			<h2>5. Propriété intellectuelle et contrefaçons</h2>
			<p>le <strong>Freelance web Nantes St Nazaire &#8211; Gaël GERARD</strong> est propriétaire des droits de propriété intellectuelle ou détient les droits d&rsquo;usage sur tous les éléments accessibles sur le site, notamment les textes, images, graphismes, logos, icônes, sons, logiciels.</p>
			<p>Toute reproduction, représentation, modification, publication, adaptation de tout ou partie des éléments du site, quel que soit le moyen ou le procédé utilisé, est interdite, sauf autorisation écrite préalable du <strong>Freelance web Nantes St Nazaire &#8211; Gaël GERARD</strong>.</p>
			<p>Toute exploitation non autorisée du site ou d&rsquo;un quelconque élément qu&rsquo;il contient sera considérée comme constitutive d&rsquo;une contrefaçon et poursuivie conformément aux dispositions des articles L.335-2 et suivants du Code de Propriété Intellectuelle.</p>
			<h2>6. Limitations de responsabilité</h2>
			<p>le <strong>Freelance web Nantes St Nazaire &#8211; Gaël GERARD</strong> ne pourra être tenue responsable des dommages directs et indirects causés au matériel de l&rsquo;utilisateur, lors de l&rsquo;accès au site de ADC, et résultant soit de l&rsquo;utilisation d&rsquo;un matériel ne répondant pas aux spécifications indiquées au point 4, soit de l&rsquo;apparition d&rsquo;un bug ou d&rsquo;une incompatibilité.</p>
			<p>le <strong>Freelance web Nantes St Nazaire &#8211; Gaël GERARD</strong> ne pourra également être tenue responsable des dommages indirects (tels que par exemple une perte de marché ou perte d&rsquo;une chance) consécutifs à l&rsquo;utilisation du site du <strong>Freelance web Nantes St Nazaire &#8211; Gaël GERARD</strong>.</p>
			<p>Des espaces interactifs (possibilité de poser des questions dans l&rsquo;espace contact) sont à la disposition des utilisateurs. le <strong>Freelance web Nantes St Nazaire &#8211; Gaël GERARD</strong> se réserve le droit de supprimer, sans mise en demeure préalable, tout contenu déposé dans cet espace qui contreviendrait à la législation applicable en France, en particulier aux dispositions relatives à la protection des données. Le cas échéant, le <strong>Freelance web Nantes St Nazaire &#8211; Gaël GERARD</strong> se réserve également la possibilité de mettre en cause la responsabilité civile et/ou pénale de l&rsquo;utilisateur, notamment en cas de message à caractère raciste, injurieux, diffamant, ou pornographique, quel que soit le support utilisé (texte, photographie&#8230;).</p>
			<h2>7. Gestion des données personnelles</h2>
			<p>En France, les données personnelles sont notamment protégées par la loi n° 78-87 du 6 janvier 1978, la loi n° 2004-801 du 6 août 2004, l&rsquo;article L. 226-13 du Code pénal et la Directive Européenne du 24 octobre 1995.</p>
			<p>A l&rsquo;occasion de l&rsquo;utilisation du site du <strong>Freelance web Nantes St Nazaire &#8211; Gaël GERARD</strong>, peuvent être recueillis : l&rsquo;URL des liens par l&rsquo;intermédiaire desquels l&rsquo;utilisateur a accédé au site du <strong>Freelance web Nantes St Nazaire &#8211; Gaël GERARD</strong>, le fournisseur d&rsquo;accès de l&rsquo;utilisateur, l&rsquo;adresse de protocole Internet (IP) de l&rsquo;utilisateur.</p>
			<p>En tout état de cause le <strong>Freelance web Nantes St Nazaire &#8211; Gaël GERARD</strong> ne collecte des informations personnelles relatives à l&rsquo;utilisateur que pour le besoin de certains services proposés par le site du <strong>Freelance web Nantes St Nazaire &#8211; Gaël GERARD</strong>. L&rsquo;utilisateur fournit ces informations en toute connaissance de cause, notamment lorsqu&rsquo;il procède par lui-même à leur saisie. Il est alors précisé à l&rsquo;utilisateur du site du <strong>Freelance web Nantes St Nazaire &#8211; Gaël GERARD</strong> l&rsquo;obligation ou non de fournir ces informations.</p>
			<p>Conformément aux dispositions des articles 38 et suivants de la loi 78-17 du 6 janvier 1978 relative à l&rsquo;informatique, aux fichiers et aux libertés, tout utilisateur dispose d&rsquo;un droit d&rsquo;accès, de rectification et d&rsquo;opposition aux données personnelles le concernant, en effectuant sa demande écrite et signée, accompagnée d&rsquo;une copie du titre d&rsquo;identité avec signature du titulaire de la pièce, en précisant l&rsquo;adresse à laquelle la réponse doit être envoyée.</p>
			<p>Aucune information personnelle de l&rsquo;utilisateur du site du <strong>Freelance web Nantes St Nazaire &#8211; Gaël GERARD</strong> n&rsquo;est publiée à l&rsquo;insu de l&rsquo;utilisateur, échangée, transférée, cédée ou vendue sur un support quelconque à des tiers. Seule l&rsquo;hypothèse du rachat du <strong>Freelance web Nantes St Nazaire &#8211; Gaël GERARD</strong> et de ses droits permettrait la transmission des dites informations à l&rsquo;éventuel acquéreur qui serait à son tour tenu de la même obligation de conservation et de modification des données vis-à-vis de l&rsquo;utilisateur du site.</p>
			<p>Le site susnommé est déclaré à la CNIL sous le numéro 2097156.</p>
			<p>Les bases de données sont protégées par les dispositions de la loi du 1er juillet 1998 transposant la directive 96/9 du 11 mars 1996 relative à la protection juridique des bases de données.</p>
			<h2 id="cookies">8. Liens hypertextes et cookies</h2>
			<p>Le site du <strong>Freelance web Nantes St Nazaire &#8211; Gaël GERARD</strong> contient un certain nombre de liens hypertextes vers d&rsquo;autres sites, mis en place avec l&rsquo;autorisation de le <strong>Freelance web Nantes St Nazaire &#8211; Gaël GERARD</strong>.</p>
			<p>Cependant, le <strong>Freelance web Nantes St Nazaire &#8211; Gaël GERARD</strong> n&rsquo;a pas la possibilité de vérifier le contenu des sites ainsi visités, et n&rsquo;assumera en conséquence aucune responsabilité de ce fait.</p>
			<p>La navigation sur le site du <strong>Freelance web Nantes St Nazaire &#8211; Gaël GERARD</strong> est susceptible de provoquer l&rsquo;installation de cookie(s) sur l&rsquo;ordinateur de l&rsquo;utilisateur. Un cookie est un fichier de petite taille, qui ne permet pas l&rsquo;identification de l&rsquo;utilisateur, mais qui enregistre des informations relatives à la navigation d&rsquo;un ordinateur sur un site. Les données ainsi obtenues visent à faciliter la navigation ultérieure sur le site, et ont également vocation à permettre diverses mesures de fréquentation.</p>
			<p>Le refus d&rsquo;installation d&rsquo;un cookie peut entraîner l&rsquo;impossibilité d&rsquo;accéder à certains services. L&rsquo;utilisateur peut toutefois configurer son ordinateur de la manière suivante, pour refuser l&rsquo;installation des cookies :</p>
			<p>Sous Internet Explorer : onglet outil / options internet.</p>
			<p>Cliquez sur Confidentialité et choisissez Bloquer tous les cookies.</p>
			<p>Validez sur OK.</p>
			<h2>9. Droit applicable et attribution de juridiction</h2>
			<p>Tout litige en relation avec l&rsquo;utilisation du site du <strong>Freelance web Nantes St Nazaire &#8211; Gaël GERARD</strong> est soumis au droit français. Il est fait attribution exclusive de juridiction aux tribunaux compétents de Nantes.</p>
			<h2>10. Les principales lois concernées</h2>
			<p>Loi n° 78-87 du 6 janvier 1978, notamment modifiée par la loi n° 2004-801 du 6 août 2004 relative à l&rsquo;informatique, aux fichiers et aux libertés.</p>
			<p>Loi n° 2004-575 du 21 juin 2004 pour la confiance dans l&rsquo;économie numérique.</p>
			<h2>11. Lexique</h2>
			<p>Utilisateur : Internaute se connectant, utilisant le site susnommé.</p>
			<p>Informations personnelles : « les informations qui permettent, sous quelque forme que ce soit, directement ou non, l&rsquo;identification des personnes physiques auxquelles elles s&rsquo;appliquent » (article 4 de la loi n° 78-17 du 6 janvier 1978).</p>
								</div>
			</article>
						</div>



					</main>

    );
  }

}
export default Legals
