/*global google*/
import React from "react";

import logo from '../img/map-marker.png';


const { compose, withProps, withHandlers } = require("recompose");
const {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker
} = require("react-google-maps");
const { InfoBox } = require("react-google-maps/lib/components/addons/InfoBox");
const demoFancyMapStyles = require("./MapStyles.json");

const StyledMapWithAnInfoBox = compose(
  withProps({
    googleMapURL: "https://maps.googleapis.com/maps/api/js?key=AIzaSyDQFWk0MDEQHtod-YBOH1_G3DcrnndRQKc&v=3.exp&libraries=geometry,drawing,places",
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `400px` }} />,
    mapElement: <div style={{ height: `100%` }} />,
    center: { lat: 47.3190756, lng: -1.8135267 },
  }),
  withHandlers(() => ({
    isOpen: false,
  }), {
    onToggleOpen: ({ isOpen }) => () => ({
      isOpen: !isOpen,
    })
  }),
  withScriptjs,
  withGoogleMap
)(props =>
  <GoogleMap
    defaultZoom={9}
    defaultCenter={props.center}
    defaultOptions={{ styles: demoFancyMapStyles }}
  >

    <Marker
      position={{ lat: 47.3190756, lng: -1.8135267 }}
      onClick={props.onToggleOpen}
      icon={logo}
      animation={google.maps.Animation.DROP}
    >
      {props.isOpen && <InfoBox
        onCloseClick={props.onToggleOpen}
        options={{ closeBoxURL: ``, enableEventPropagation: true }}
      >
        <div style={{ backgroundColor: `rgba(32,125,193,0.75)`,  padding: `12px`, color:'#ffffff' }}>
          <div style={{ fontSize: `16px`, Color: `#ffffff` , width: '200px'}}>
            C'est ici que vous me trouverez !
            <br/>
            <a style={{ fontSize: `16px`, color: `#ffffff`, fontWeight: 'bold', padding: '12px 0 0', textDecoration: 'none', display:'block' }} href="https://www.google.com/maps/place/Freelance+-+Conseil+et+Cr%C3%A9ation+en+communication+Internet+et+Mobile/@47.319072,-1.8135267,17z/data=!3m1!4b1!4m5!3m4!1s0x0:0xb4fc6ec95929ca11!8m2!3d47.319072!4d-1.811338?hl=fr" target="_blank" rel="noopener noreferer">Afficher l'Itinéraire <i class="fa fa-external-link" aria-hidden="true"></i></a>
          </div>
        </div>
      </InfoBox>}
    </Marker>
  </GoogleMap>
);


export default StyledMapWithAnInfoBox;

//var map = document.getElementById("surabaya");
//React.render(MyMapComponent, map);
