import React, { Component } from 'react';
import { Form } from 'reactstrap';
import { Navigate } from "react-router-dom";
import axios from "axios";

class ContactForm extends Component {
  state = {
    name: '',
    email: '',
    phone: '',
    subject: '',
    message: '',
  }
  
 state = { messageSent: false };
 handleChange = (param, e) => {
   this.setState({ [param]: e.target.value })
 }
  handleSubmit(e) {
    e.preventDefault()
    const { name, email, phone, subject, message } = this.state
    let formValues = {
      from_name: name,
      from_email: email,
      from_phone: phone,
      to_name: 'Gaël',
      subject: subject,
      message_html: message,
      source: window.location.href,
     }

    axios.post('https://dev.gaelgerard.com/react-connexion.php', formValues)
    .then(response => {
        console.log('Response:', response.data);
         this.setState({ messageSent: true });
    })
    .catch(error => {
        console.error('Error:', error);
    });
 }
 resetForm() {
    this.setState({
      name: '',
      email: '',
      phone: '',
      subject: '',
      message: '',
    })
  }
  render() {
    return (
      <>
        {this.state.messageSent && (
          <Navigate to="/thank-you" replace={true} />
        )}
          <Form onSubmit={this.handleSubmit.bind(this)} className="form">
            <div className="row">
              <div className="col-md-4 col-lg-4 col-sm-4 col-xs-12">
              <input
                type="email"
                name="email"
                value={this.state.email}
                onChange={this.handleChange.bind(this, 'email')}
                required  placeholder="What's your email*"
              />
              </div>
              <div className="col-md-4 col-lg-4 col-sm-4 col-xs-12">
              <input
                type="text"
                name="name"
                className="inputName"
                value={this.state.name}
                onChange={this.handleChange.bind(this, 'name')}
                required placeholder="What's your name*"
              />
                </div>
              <div className="col-md-4 col-lg-4 col-sm-4 col-xs-12">
              <input
                type="text"
                name="phone"
				inputMode="decimal"
				autoComplete="tel"
                className="inputName"
				value={this.state.phone}
                onChange={this.handleChange.bind(this, 'phone')}
                required placeholder="What's your phone number*"
              />
                </div>
			</div>

                            <div className="clearfix"></div>
              <textarea
                type="textarea"
                name="message"
                value={this.state.message}
                onChange={this.handleChange.bind(this, 'message')}
                required placeholder="What's in your mind*">
              </textarea>
                            <div className="clearfix mb-5"></div>

                            <div className="control-group">
                            <label className="gdprCheckbox control control-checkbox">
                                <input name="gdpr_terms" id="gdpr_terms" value="Accepte conditions" required type="checkbox" className="mr-1"/>
            <div className="control_indicator"></div> J’accepte <a href="https://dev.gaelgerard.com/mentions-legales/?utm_source=site&utm_medium=page_contact" target="_blank" rel="noopener noreferrer">les conditions</a> et <a href="https://dev.gaelgerard.com/politique-de-confidentialite/?utm_source=site&utm_medium=page_contact" target="_blank"rel="noopener noreferrer">la politique de confidentialité</a>        *
                            </label>
                            </div>
                            <input name="" type="submit" value="Envoyer Email"/>

                            <div id="simple-msg"></div>
                            <br/>
                            <p className="small">* What's = What is = Quel est votre nom complet, quelle est votre adresse email, quel est votre numéro de téléphone, qu'avez vous à l'esprit / quel est votre message ?<br/>Et oui, tous les champs sont obligatoires :/ </p>
          </Form>
      </>
    )
  }
}export default ContactForm
