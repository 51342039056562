import React, { Component } from 'react';
import logoFooter from './../pages/img/logoGGERARD-carre250.png';
import { NavLink } from 'react-router-dom'

class Footer extends Component {
  
componentDidMount() {
 
}
  render() {

  // Import result is the URL of your image
  return (

       <footer role="footer">



                    <NavLink to="/" title="Freelance Web Nantes Saint-Nazaire" className="logoFooter" id="logofooterHome"><img src={logoFooter} title="Freelance Web Nantes Saint-Nazaire" alt="Freelance Web Nantes Saint-Nazaire"/></NavLink>


            <nav role="footer-nav">

            	<ul>



                          <li className="nav-active"><NavLink to="/" title="Accueil" id="navfooterHome">Home</NavLink></li>

                            <li><NavLink to="/freelance-web-nantes-st-nazaire-about" title="About" id="navfooterAbout">À propos</NavLink></li>

                            <li><NavLink to="/freelance-web-nantes-st-nazaire-portfolio" title="Portfolio" id="navfooterPortfolio">Portfolio</NavLink></li>

                            <li><NavLink to="/creer-site-internet-nantes-st-nazaire-blog" title="Blog" id="navfooterBlog">Blog</NavLink></li>

                            <li><NavLink to="/contact-freelance-web-nantes-st-nazaire" title="Contact" id="navfooterContact">Contact</NavLink></li>


                </ul>
                <p className="rdvclients mt-3">Nouveau ! <a target="_blank" href="https://rdvclients.fr/rendez-vous-direct/gael-gerard-goodgame-communication/?utm_source=site-ggcom&amp;utm_medium=referral&amp;utm_campaign=footer">Programmez un RDV Direct</a></p>

            </nav>



            <p className="copy-right">&copy; 2013 - {(new Date().getFullYear())} <NavLink to="/" id="footerHome">www.gaelgerard.com</NavLink> All rights Reserved - <NavLink to="/mentions-legales" id="footerMentions">Mentions légales</NavLink></p>

								<div id="cookie-bar" className="cookie-message fixed bottom">
								<p className="row Flexbox"><span>Ce site utilise des cookies pour vous offrir une meilleure navigation. En poursuivant votre visite, vous acceptez l'utilisation de ces cookies.</span> <a className="my-close-button btn btn-primary btn-lg">Ok</a>
									</p>
				</div>

        </footer>
  )
}
}

export default Footer;
