import React, { Component } from 'react';
import '../css/contact.css';

import {Helmet} from "react-helmet";
import logoFooter from './img/logoGGERARD-carre250.png';


     

class Thanx extends Component {
  
  
  componentDidMount() {
    window.scrollTo(0,0);
  }
  render() {
    return (
      
        <main role="main-inner-wrapper" className="container">
          <Helmet>
            <title>Remerciement formulaire de contact</title>
            <meta charset="UTF-8" />    
            <meta property="og:image" content={logoFooter} />
            <meta name="robots" content="noindex" />
          </Helmet>
          <div className="row">
            	<div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 grid">
      	<article role="pge-title-content" className="contact-header">

                    	<header>
                              <h1><span>Merci!</span> Votre demande est bien envoyée</h1>
                        </header>          
                    </article>

                </div>


                <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 grid">

      	<article role="pge-title-content" className="contact-header thanx">
                        
                              <p>Nous avons reçu votre demande par email et nous y répondrons dans les meilleurs délais.</p> <br/>
</article>
                </div>
                </div>
            <div className="row text-center">
            <div className="container">
                              <p className="lead">En cas d'urgence, vous pouvez également nous contacter par téléphone et laisser un message si pas de réponse.</p>
                              <a className="btn btn-primary btn-lg mt-2" href={"tel:0033285523866"}><i className="fa fa-phone mr-3" aria-hidden="true"></i>
 <span className="ml-3">&nbsp;Appeler 02 85 52 38 66</span></a>
                        
            </div>
            </div>
               </main>
    );
  }
}
export default Thanx